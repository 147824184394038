const serviceData = [
  {
      requestForm: "User Access Management",
      category: "Identity & Access",
      types: [
          {
              name: "Add / Remove User Access",
              chargingState: "No charge",
              appliesTo: {
                  "Fully Managed": true,
                  "Co-Managed": true,
                  "Self Managed": true
              }
          },
          {
              name: "VPN Access request",
              chargingState: "No charge",
              appliesTo: {
                  "Fully Managed": true,
                  "Co-Managed": true,
                  "Self Managed": true
              }
          },
          {
              name: "OKTA Access request",
              chargingState: "No charge",
              appliesTo: {
                  "Fully Managed": true,
                  "Co-Managed": true,
                  "Self Managed": true
              }
          },
          {
              name: "OKTA access decommission",
              chargingState: "No charge",
              appliesTo: {
                  "Fully Managed": true,
                  "Co-Managed": true,
                  "Self Managed": true
              }
          },
          {
              name: "Add / Remove Role Access",
              chargingState: "No charge",
              appliesTo: {
                  "Fully Managed": true,
                  "Co-Managed": true,
                  "Self Managed": true
              }
          },
          {
              name: "Add / Remove Group Access",
              chargingState: "No charge",
              appliesTo: {
                  "Fully Managed": true,
                  "Co-Managed": true,
                  "Self Managed": true
              }
          },
          {
              name: "Add / Remove OpenCloud Portal access",
              chargingState: "No charge",
              appliesTo: {
                  "Fully Managed": true,
                  "Co-Managed": true,
                  "Self Managed": true
              }
          },
          {
              name: "Add Additional users to Sandbox",
              chargingState: "No charge",
              appliesTo: {
                  "Fully Managed": true,
                  "Co-Managed": true,
                  "Self Managed": true
              }
          },
          {
              name: "Create Group (Okta/AD)",
              chargingState: "No charge",
              appliesTo: {
                  "Fully Managed": true,
                  "Co-Managed": true,
                  "Self Managed": true
              }
          },
          {
              name: "Assign / Remove Group Role",
              chargingState: "No charge",
              appliesTo: {
                  "Fully Managed": true,
                  "Co-Managed": true,
                  "Self Managed": true
              }
          },
          {
              name: "Remove / Disable Group",
              chargingState: "No charge",
              appliesTo: {
                  "Fully Managed": true,
                  "Co-Managed": true,
                  "Self Managed": true
              }
          },
          {
              name: "MFA reset",
              chargingState: "No charge",
              appliesTo: {
                  "Fully Managed": true,
                  "Co-Managed": true,
                  "Self Managed": true
              }
          }
      ]
  },
  {
      requestForm: "Service Modification",
      category: "Provisioning",
      types: [
          {
              name: "New Production Pattern",
              chargingState: "No charge",
              appliesTo: {
                  "Fully Managed": true,
                  "Co-Managed": true,
                  "Self Managed": true
              }
          },
          {
              name: "New Non-Production Pattern",
              chargingState: "No charge",
              appliesTo: {
                  "Fully Managed": true,
                  "Co-Managed": true,
                  "Self Managed": true
              }
          },
          {
              name: "New Production Account",
              chargingState: "No charge",
              appliesTo: {
                  "Fully Managed": true,
                  "Co-Managed": true,
                  "Self Managed": true
              }
          },
          {
              name: "New Non-Production Account",
              chargingState: "No charge",
              appliesTo: {
                  "Fully Managed": true,
                  "Co-Managed": true,
                  "Self Managed": true
              }
          },
          {
              name: "New 90-Day Sandbox",
              chargingState: "No charge",
              appliesTo: {
                  "Fully Managed": true,
                  "Co-Managed": true,
                  "Self Managed": true
              }
          },
          {
              name: "Decommission Account (PPE/Standard/Sandbox)",
              chargingState: "No charge",
              appliesTo: {
                  "Fully Managed": true,
                  "Co-Managed": true,
                  "Self Managed": true
              }
          }
      ]
  },
  {
      requestForm: "Service Modification",
      category: "Modifications",
      types: [
          {
              name: "Security Modifications",
              chargingState: "No charge",
              appliesTo: {
                  "Fully Managed": true,
                  "Co-Managed": false,
                  "Self Managed": false
              }
          },
          {
              name: "IaaS Cloud Infrastructure Modifications",
              chargingState: "No charge",
              appliesTo: {
                  "Fully Managed": true,
                  "Co-Managed": false,
                  "Self Managed": false
              }
          },
          {
              name: "PaaS Modifications",
              chargingState: "No charge",
              appliesTo: {
                  "Fully Managed": true,
                  "Co-Managed": true,
                  "Self Managed": false
              }
          },
          {
              name: "Networking Modifications",
              chargingState: "No charge",
              appliesTo: {
                  "Fully Managed": true,
                  "Co-Managed": true,
                  "Self Managed": false
              }
          },
          {
              name: "Vulnerability Management Modifications",
              chargingState: "No charge",
              appliesTo: {
                  "Fully Managed": true,
                  "Co-Managed": false,
                  "Self Managed": false
              }
          },
          {
              name: "Patch Management Modifications",
              chargingState: "No charge",
              appliesTo: {
                  "Fully Managed": true,
                  "Co-Managed": false,
                  "Self Managed": false
              }
          }
      ]
  },
  {
      requestForm: "Service Modification",
      category: "Tooling",
      types: [
          {
              name: "Onboard / off board accounts to Tools",
              chargingState: "No charge",
              appliesTo: {
                  "Fully Managed": true,
                  "Co-Managed": true,
                  "Self Managed": false
              }
          },
          {
              name: "Add / Remove users from Tools",
              chargingState: "No charge",
              appliesTo: {
                  "Fully Managed": true,
                  "Co-Managed": true,
                  "Self Managed": false
              }
          }
      ]
  },
  {
      requestForm: "Advisory Request",
      category: "Billing",
      types: [
          {
              name: "Billing Support",
              chargingState: "No charge",
              appliesTo: {
                  "Fully Managed": true,
                  "Co-Managed": true,
                  "Self Managed": true
              }
          }
      ]
  },
  {
      requestForm: "Advisory Request",
      category: "Optimisation",
      types: [
          {
              name: "One-off Cost Optimisation Review",
              chargingState: "Chargeable",
              appliesTo: {
                  "Fully Managed": true,
                  "Co-Managed": true,
                  "Self Managed": false
              }
          },
          {
              name: "One-off Architectural Review",
              chargingState: "Chargeable",
              appliesTo: {
                  "Fully Managed": true,
                  "Co-Managed": true,
                  "Self Managed": false
              }
          }
      ]
  },
  {
      requestForm: "Advisory Request",
      category: "Automation",
      types: [
          {
              name: "Automation Development Request",
              chargingState: "Chargeable",
              appliesTo: {
                  "Fully Managed": true,
                  "Co-Managed": true,
                  "Self Managed": false
              }
          }
      ]
  },
  {
      requestForm: "Advisory Request",
      category: "Governance Risk and Compliance",
      types: [
          {
              name: "Guardrail Exception Request (Layer 0)",
              chargingState: "No charge",
              appliesTo: {
                  "Fully Managed": true,
                  "Co-Managed": true,
                  "Self Managed": false
              }
          },
          {
              name: "Guardrail Exception Request (Layer 1)",
              chargingState: "No charge",
              appliesTo: {
                  "Fully Managed": true,
                  "Co-Managed": true,
                  "Self Managed": false
              }
          },
          {
              name: "Vulnerability Exception Request",
              chargingState: "No charge",
              appliesTo: {
                  "Fully Managed": true,
                  "Co-Managed": false,
                  "Self Managed": false
              }
          }
      ]
  }
];

export default serviceData;
