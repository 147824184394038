import {
  CContainer, CCol, CInput, CRow, CTextarea, CCheckbox, CFormGroup, CLabel, CInputRadio, CHeader, CHeaderText
} from "@coreui/react";
import classNames from "classnames";
import ToasterProvider from "components/common/Context/ToasterContext";
import { LoadingSpinnerOverlay } from "components/common/loading";
import React, { useContext, useState } from "react";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { useExtension } from "utils";
import { useUserAuth } from "utils/hooks";
import Joi from "joi";
import Select from "react-select";
import RequestSuccess from "./components/RequestSuccess";
import { Tooltip } from "components/common/tooltip";
import FormActions from "./FormActions";
import { postCMSEnquiry } from "extensions/usoc/api/service"
import "./RequestStyles.scss";
import { UserSelectDropdown } from "components/common/forms"
import wbsCodeValidation from "utils/validate/validateWBS"
import { CMSTermsAndConditionModal } from "components/common/modal"

const formInputConfig = {
  generalInformation: {
    name: "generalInformation",
    label: "General Information",
    type: "heading"
  },
  termsAndConditions: {
    name: "termsAndConditions",
    label: "I have authorisation to agree to the Terms & Conditions",
    type: "t-and-c"
  },
  clientName: {
    name: "clientName",
    label: "Asset Name",
    type: "text",
    placeholder: "Enter the name of the asset"
  },
  projectName: {
    name: "projectName",
    label: "Project Name",
    type: "text",
    placeholder: "Enter the project name"
  },
  projectDescription: {
    name: "projectDescription",
    label: "Project Description",
    type: "textarea",
    placeholder: "Describe this project"
  },

  industry: {
    name: "industry",
    label: "Industry",
    type: "select",
    placeholder: "Select industry",
    tooltip: "Select the industry relevant to your project.",
    options: [
      { label: "Financial & Legal", value: "Financial & Legal", key: "1" },
      { label: "Consumer", value: "Consumer", key: "2" },
      {
        label: "Energy, Resources & Industrials",
        value: "Energy, Resources & Industrials",
        key: "3"
      },
      {
        label: "Government & Public Services",
        value: "Government & Public Services",
        key: "4"
      },
      {
        label: "Life Sciences & Health Care",
        value: "Life Sciences & Health Care",
        key: "5"
      },
      {
        label: "Technology, Media & Telecommunications",
        value: "Technology, Media & Telecommunications",
        key: "6"
      },
      { label: "Private Equity", value: "Private Equity", key: "7" },
      { label: "Cross Sector", value: "Cross Sector", key: "8" },
      { label: "Other", value: "Other", key: "9" }
    ]
  },
  subIndustry: {
    name: "subIndustry",
    label: "Sub-Industry",
    type: "select",
    placeholder: "Select sub-industry",
    tooltip: "Select the sub-industry relevant to your project.",
    options: [
      {
        label: "Retail, Wholesale & Distribution",
        value: "Retail, Wholesale & Distribution",
        key: "0"
      },
      { label: "Automotive", value: "Automotive", key: "1" },
      {
        label: "Transportation, Hospitality & Services",
        value: "Transportation, Hospitality & Services",
        key: "2"
      },
      { label: "Consumer Products", value: "Consumer Products", key: "3" },
      {
        label: "Power, Utilities & Renewables",
        value: "Power, Utilities & Renewables",
        key: "3"
      },
      {
        label: "Oil, Gas & Chemicals",
        value: "Oil, Gas & Chemicals",
        key: "5"
      },
      {
        label: "Industrial Products & Construction",
        value: "Industrial Products & Construction",
        key: "6"
      },
      {
        label: "Banking & Capital Markets",
        value: "Banking & Capital Markets",
        key: "7"
      },
      { label: "Insurance", value: "Insurance", key: "3" },
      {
        label: "Defence, Security & Justice",
        value: "Defence, Security & Justice",
        key: "8"
      },
      {
        label: "Health & Social Care",
        value: "Health & Social Care",
        key: "9"
      },
      { label: "Civil Government", value: "Civil Government", key: "10" },
      { label: "Transport", value: "Transport", key: "11" },
      { label: "Health Care", value: "Health Care", key: "12" },
      { label: "Life Sciences", value: "Life Sciences", key: "13" },
      {
        label: "Telecom, Media & Entertainment",
        value: "Telecom, Media & Entertainment",
        key: "14"
      }
    ]
  },
  department: {
    name: "department",
    label: "Business Function",
    type: "select",
    placeholder: "Select the Business Function",
    tooltip: "Select the Fucntion the project sits in (previoulsy 'Service Line').",
    options: [
      { label: "Select...", value: "", key: "0" },
      { label: "Tax & Legal", value: "TL", key: "1" },
      { label: "Audit & Assurance", value: "AA", key: "2" },
      { label: "Strategy, Risk & Transactions​", value: "SRT", key: "3" },
      { label: "Technology & Transformation​", value: "TT", key: "4" },
      { label: "ITS​", value: "ITS​", key: "5" },
      { label: "Enabling Functions​", value: "ENA", key: "6" }
    ]
  },
  engagementManager: {
    name: "engagementManager",
    label: "Engagement Manager",
    type: "text",
    placeholder: "Enter the engagement manager email address"
  },
  financialInformation: {
    name: "financialInformation",
    label: "Financial",
    type: "heading"
  },
  billingPartner: {
    name: "billingPartner",
    label: "Engagement Partner/Accountable Partner",
    type: "text",
    placeholder: "Enter the responsible partner's email address"
  },
  wbsCode: {
    name: "wbsCode",
    label: "WBS Code",
    type: "text",
    placeholder: "Enter the WBS code"
  },
  complianceInformation: {
    name: "complianceInformation",
    label: "Compliance Information",
    type: "heading"
  },
  infosecReference: {
    name: "infosecReference",
    label: "Have you engaged with Infosec?",
    type: "optional-text",
    placeholder: "Enter the InfoSec reference for the project",
    options: [
      { label: "Yes", value: "yes", key: "0" },
      { label: "No", value: "no", key: "1" }
    ]
  },
  gtomReference: {
    name: "gtomReference",
    label:
      "Have you engaged with GTOM? (Global Technology Operations Management)",
    type: "optional-text",
    placeholder: "Enter the GTOM reference for the project",
    options: [
      { label: "Yes", value: "yes", key: "0" },
      { label: "No", value: "no", key: "1" }
    ]
  },
  dataType: {
    name: "dataType",
    label: "Please confirm the type of data in the solution",
    type: "select",
    options: [
      { label: "PII", value: "pii", key: "1" },
      {
        label: "Deloitte Confidential",
        value: "deloitteConfidential",
        key: "2"
      },
      { label: "Client Confidential", value: "clientConfidential", key: "3" },
      { label: "Healthcare", value: "healthcare", key: "4" },
      { label: "No Data", value: "noData", key: "5" },
      { label: "Other", value: "other", key: "6" }
    ]
  },
  dataOwner: {
    name: "dataOwner",
    label: "Data Owner",
    type: "text",
    placeholder:
      "Enter the email address of the person responsible for the data"
  },
  technicalInformation: {
    name: "technicalInformation",
    label: "Technical Requirements",
    type: "heading"
  },
  technicalOwner: {
    name: "technicalOwner",
    label: "Technical Owner",
    type: "text",
    placeholder: "Enter the technical owner email address"
  },
  cloudServiceProvider: {
    name: "cloudServiceProvider",
    label: "Cloud Service Provider",
    type: "select",
    options: [
      { label: "Select...", value: "", key: "0" },
      { label: "AWS", value: "AWS", key: "1" },
      { label: "Azure", value: "Azure", key: "2" },
      { label: "GCP", value: "GCP", key: "3" }
    ]
  },
  cmsSupportDetails: {
    name: "cmsSupportDetails",
    label: "Do you require support from CMS",
    type: "optional-text",
    placeholder: "What support do you require from CMS?",
    options: [
      { label: "Yes", value: "yes", key: "yes" },
      { label: "No", value: "no", key: "no" }
    ]
  },
  numberOfEnvironments: {
    name: "numberOfEnvironments",
    label: "Number of Non-Production environments",
    type: "select",
    options: [
      { label: "Select...", value: "", key: "0" },
      { label: "1", value: "1", key: "1" },
      { label: "2", value: "2", key: "2" },
      { label: "3", value: "3", key: "3" },
      { label: "4", value: "4", key: "4" },
      { label: "5", value: "5", key: "5" }
    ]
  },
  provisioningTimeline: {
    name: "provisioningTimeline",
    label:
      "When is access to the cloud environment required for? (3-day lead time)",
    type: "date",
    placeholder: "Select the date"
  },
  publicAccess: {
    name: "publicAccess",
    label: "Publicly accessible over the Internet?",
    type: "radio",
    options: [
      { label: "Yes", value: "yes", key: "yes" },
      { label: "No", value: "no", key: "no" }
    ]
  },
  clientConnectivity: {
    name: "clientConnectivity",
    label: "Direct client site connectivity (e.g., site-to-site VPN)?",
    type: "radio",
    options: [
      { label: "Yes", value: "yes", key: "yes" },
      { label: "No", value: "no", key: "no" }
    ]
  },
  dataLocation: {
    name: "dataLocation",
    label: "Client requirement for data location",
    type: "select",
    options: [
      { label: "Select...", value: "", key: "0" },
      { label: "None", value: "none", key: "1" },
      { label: "UK", value: "uk", key: "2" },
      { label: "EU", value: "eu", key: "3" },
      { label: "EEA", value: "eea", key: "4" }
    ]
  },
  serviceInformation: {
    name: "serviceInformation",
    label: "Service Requirements",
    type: "heading"
  },
  serviceLevel: {
    name: "serviceLevel",
    label: "CMS Service Requirement",
    type: "radio",
    options: [
      { label: "Fully Managed", value: "fully_managed", key: "fullyManaged" },
      { label: "Co-Managed", value: "co_managed", key: "coManaged" },
      { label: "Self-Managed", value: "self_managed", key: "selfManaged" }
    ]
  },
  cloudConsumption: {
    name: "cloudConsumption",
    label: "Estimated monthly cloud consumption",
    type: "select",
    options: [
      { label: "£0 - £999", value: "999", key: "0-999" },
      { label: "£1,000 - £4,999", value: "4999", key: "1000-4999" },
      { label: "£5,000 and above", value: "5000", key: "5000+" },
      { label: "Don't Know", value: "0", key: "dontknow" }
    ]
  },
  erdcRestrictions: {
    name: "erdcRestrictions",
    label:
      "Are there any restrictions in using ERDC (Romania) in support of your solution?",
    type: "radio",
    options: [
      { label: "Yes", value: "no", key: "0" },
      { label: "No", value: "yes", key: "1" }
    ]
  },
  productionGoLive: {
    name: "productionGoLive",
    label:
      "Is there a known date for production services to go live to the client?",
    type: "optional-date",
    placeholder: "Select the date",
    options: [
      { label: "Yes", value: "yes", key: "0" },
      { label: "No", value: "no", key: "1" }
    ]
  },
  supportAgreement: {
    name: "supportAgreement",
    label: "Support Hours",
    type: "select",
    options: [
      {
        label: "Standard support hours, Monday - Friday 08:00 - 18:00 GMT",
        value: "818",
        key: "818"
      },
      {
        label: "24/7 On-Call Support for Production P1 incidents only (subject to additional charges)",
        value: "247",
        key: "247"
      }
    ]
  }
}

const inputSchema = Joi.object({
  clientName: Joi.string().required().messages({
    "any.required":
      "Please ensure you specify the client name or Multiple if multiple clients",
    "string.empty":
      "Please ensure you specify the client name or Multiple if multiple clients"
  }),
  projectName: Joi.string().required().messages({
    "any.required": "Please provide the name you wish to refer to this project",
    "string.empty": "Please provide the name you wish to refer to this project"
  }),
  projectDescription: Joi.string().required().messages({
    "any.required": "Please provide a brief description of this project",
    "string.empty": "Please provide a brief description of this project"
  }),
  industry: Joi.string().required().messages({
    "any.required": "Cannot be an empty field",
    "string.empty":
      "Please confirm the main industry the solution is being built delivered to"
  }),
  subIndustry: Joi.string().required().messages({
    "any.required": "Cannot be an empty field",
    "string.empty":
      "Please confirm the sub-industry the solution is being built delivered to"
  }),
  department: Joi.string().required().messages({
    "any.required": "Cannot be an empty field",
    "string.empty":
      "Please confirm the Offer Portfolio the solution is being built delivered to"
  }),
  engagementManager: Joi.string()
    .email({ tlds: { allow: false } })
    .required()
    .messages({
      "any.required": "Cannot be an empty field",
      "string.empty":
        "Please provide the name of the engagement manager for this project"
    }),
  billingPartner: Joi.string()
    .email({ tlds: { allow: false } })
    .required()
    .messages({
      "any.required": "Cannot be an empty field",
      "string.empty":
        "Please provide the name of the billing partner for this project"
    }),
  wbsCode: Joi.string().custom(wbsCodeValidation).required().messages({
    "any.required":
      "Please specify a Level 4 WBS Code that will be used for all costs associated with this project",
    "string.empty":
      "Please specify a Level 4 WBS Code that will be used for all costs associated with this project"
  }),
  infosecReference: Joi.string().optional().allow(""),
  infosecReferenceSelection: Joi.string().required().messages({
    "string.empty": "Cannot be an empty field"
  }),
  gtomReference: Joi.string().optional().allow(""),
  gtomReferenceSelection: Joi.string().required().messages({
    "string.empty": "Cannot be an empty field"
  }),
  dataType: Joi.string().required().messages({
    "any.required": "Cannot be an empty field",
    "string.empty":
      "Please confirm the type of data that will be stored and/or processed in the solution"
  }),
  dataOwner: Joi.string()
    .email({ tlds: { allow: false } })
    .required()
    .messages({
      "any.required": "Cannot be an empty field",
      "string.empty":
        "Please provide the email address of the person responsible for the data"
    }),
  technicalOwner: Joi.string()
    .email({ tlds: { allow: false } })
    .required()
    .messages({
      "any.required": "Cannot be an empty field",
      "string.empty":
        "Please provide the name of the technical owner for this project"
    }),
  cloudServiceProvider: Joi.string().required().messages({
    "any.required": "Cannot be an empty field",
    "string.empty": "Please specify the cloud service provider"
  }),
  cmsSupportDetails: Joi.string().optional().allow(""),
  cmsSupportDetailsSelection: Joi.string().required().messages({
    "string.empty": "Please indicate what support you require from CMS"
  }),
  numberOfEnvironments: Joi.string().required().messages({
    "any.required": "Cannot be an empty field",
    "string.empty":
      "Please specify the number of non-production environments required"
  }),
  provisioningTimeline: Joi.date().required().messages({
    "any.required": "Cannot be an empty field",
    "date.base": "Invalid date for provisioning timeline"
  }),
  publicAccess: Joi.string().required().messages({
    "any.required": "Cannot be an empty field",
    "string.empty":
      "Please specify whether this solution is intended to be internet facing"
  }),
  clientConnectivity: Joi.string().required().messages({
    "any.required": "Cannot be an empty field",
    "string.empty":
      "Please specify whether this solution requires dedicated connectivity to the client"
  }),
  dataLocation: Joi.string().required().messages({
    "any.required": "Cannot be an empty field",
    "string.empty": "Please specify the data location requirements"
  }),
  serviceLevel: Joi.string().required().messages({
    "any.required": "Cannot be an empty field",
    "string.empty": "Please specify the service level required"
  }),
  cloudConsumption: Joi.string().required().messages({
    "any.required": "Cannot be an empty field",
    "string.empty": "Please provide an indication of expected CSP monthly spend"
  }),
  erdcRestrictions: Joi.string().allow("").messages({
    "string.empty":
      "Please indicate whether there are client/contractual requirements that may prohibit use of Romania Delivery Centre"
  }),
  productionGoLive: Joi.date().optional().allow(""),
  productionGoLiveSelection: Joi.string().required().messages({
    "any.required": "Cannot be an empty field"
  }),
  supportAgreement: Joi.string().required().messages({
    "any.required": "Please specify the support hours required",
  }),
  termsAndConditions: Joi.boolean().required().messages({
    "any.required": "You must agree to the CMS Terms & Conditions"
  })
})

const validateField = (field, value) => {
  return inputSchema.extract(field).validate(value, { abortEarly: false });
};

export default function ModalCloudHostingForm() {
  const auth = useUserAuth();
  const extension = useExtension();
  const host = extension?.config?.env?.clientApiGateway?.URL;
  const projectID = useSelector((state) => state.settings.currentProject.id);
  const history = useHistory();
  const enqueueToast = useContext(ToasterProvider);
  const [isTermsAndConditionsChecked, setIsTermsAndConditionsChecked] = useState(false);
  const [showTermsAndConditionsModal, setShowTermsAndConditionsModal] = useState(true);
  
  const updateFormDataForTCsAcceptance = (isChecked) => {
    setIsTermsAndConditionsChecked(isChecked);
    setFormData(prevFormData => ({
      ...prevFormData,
      termsAndConditions: isChecked,
    }));
    setShowTermsAndConditionsModal(false);
  };

  const [formData, setFormData] = useState({
    clientName: "",
    projectName: "",
    projectDescription: "",
    industry: "",
    subIndustry: "",
    department: "",
    engagementManager: "",
    billingPartner: "",
    wbsCode: "",
    gtomReference: "",
    gtomReferenceSelection: "",
    infosecReference: "",
    dataType: "",
    dataOwner: "",
    technicalOwner: "",
    cloudServiceProvider: "",
    cmsSupportDetails: "",
    numberOfEnvironments: "",
    provisioningTimeline: "",
    publicAccess: "",
    clientConnectivity: "",
    dataLocation: "",
    serviceLevel: "",
    cloudConsumption: "",
    erdcRestrictions: "",
    productionGoLive: "",
    productionGoLiveSelection: "",
    supportAgreement: "false",
    termsAndConditions: isTermsAndConditionsChecked,
  });

  const [formErrors, setFormErrors] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [formSubmitted, setFormSubmitted] = useState(false);
  const [requestNumber, setRequestNumber] = useState("");

  const resetFormInput = () => {
    setFormData({
      clientName: "",
      projectName: "",
      projectDescription: "",
      industry: "",
      subIndustry: "",
      department: "",
      engagementManager: "",
      billingPartner: "",
      wbsCode: "",
      gtomReference: "",
      gtomReferenceSelection: "",
      infosecReference: "",
      dataType: "",
      dataOwner: "",
      technicalOwner: "",
      cloudServiceProvider: "",
      cmsSupportDetails: "",
      numberOfEnvironments: "",
      provisioningTimeline: "",
      publicAccess: "",
      clientConnectivity: "",
      dataLocation: "",
      serviceLevel: "",
      cloudConsumption: "",
      erdcRestrictions: "",
      productionGoLive: "",
      productionGoLiveSelection: "",
      supportAgreement: "",
      termsAndConditions: isTermsAndConditionsChecked,
    });
  };
  const resetFormError = () => {
    setFormErrors({});
  };

  const setFormError = (field, error) => {
    setFormErrors((prevErrors) => ({
      ...prevErrors,
      [field]: error,
    }));
  };

  const handleChange = (field) => (e) => {  
    const value = e.target.type === "checkbox" ? e.target.checked : e.target.value;
    setFormData((prevFormData) => ({
      ...prevFormData,
      [field]: value,
    }));

    const { error } = validateField(field, value);
    if (error) {
      setFormError(field, error.details[0].message);
    } else {
      setFormError(field, "");
    }
  };

  const handleFormSubmit = async () => {
    resetFormError();
    const { error } = await inputSchema.validate(formData, { abortEarly: false });
    const provisioningDate = new Date(formData.provisioningTimeline);
    const productionGoLiveDate = new Date(formData.productionGoLive);
    const hasProdGoLiveDate = formData.productionGoLiveSelection === "yes";
    const hasGTOMReference = formData.gtomReferenceSelection === "yes";

    if (hasProdGoLiveDate) {
      if (productionGoLiveDate < provisioningDate) {
        setFormError('productionGoLive', 'Production Go Live must be after the Provisioning Timeline.');
        return; 
      }
    }

    if (error) {
      console.log("Error: ", error);
      error.details.forEach((eachError) => {
        setFormError(eachError.path[0], eachError.message);
      });
      const invalidFields = error.details.map(detail => detail.path[0]); // Assuming 'path[0]' gives the field name

      // Scroll to the first invalid field
      if (invalidFields.length > 0) {
        const firstInvalidField = document.querySelector(`[name="${invalidFields[0]}"]`); // Adjust the selector if needed
        if (firstInvalidField) {
          firstInvalidField.scrollIntoView({ behavior: 'smooth', block: 'center' }); // Smooth scroll to top of the field
        }
      }
    } 
    else 
    {
      setIsLoading(true);
      const FOLLOW_UP_SURVEY = "CMS Follow-up Survey"
      const requestBody = {
        short_description: FOLLOW_UP_SURVEY,
        requested_by: auth.email,
        ...formData,
      };
      const response = await postCMSEnquiry(
        {
          auth,
          host,
          body: JSON.stringify(requestBody),
        },
        projectID
      );

      if (response.error) {
        enqueueToast(
          "failure",
          "New Customer Environment Request Failed",
          "Something went wrong... please try again later"
        );
      } else {
        setRequestNumber(response.data.ticket_number);
        setFormSubmitted(true);
        enqueueToast(
          "success",
          "New Customer Environment Request Successful",
          `Request Id: ${response.data.ticket_number}`
        );
      }
      setIsLoading(false);
      history.push("/landing")
    }
  };

  const handleFormCancel = () => {
    resetFormInput();
    resetFormError();
    history.go(-1);
  };

  if (formSubmitted) {
    return <RequestSuccess requestNumber={requestNumber} />;
  }

  return (
    <CContainer className="service-request-container py-2">
      <LoadingSpinnerOverlay isLoading={isLoading} spinnerSize="8rem">
        <CRow className="m-2 justify-content-center">
          <CCol lg={6} md={8} sm={12}>
            <p className="heading py-2 text-center">New Cloud Hosting Request</p>
          </CCol>
        </CRow>
        <CRow className="m-2 justify-content-center">
          <CCol lg={6} md={8} sm={12}>
            <p className="sub-heading">
              Fill out the below form to create a Cloud Hosting request with Deloitte Cloud Managed Services.
            </p>
          </CCol>
        </CRow>

        <CContainer className="m-2">
          {Object.entries(formInputConfig).map(([key, config]) => {
            const error = formErrors[key];
            switch (config.type) {
              case "text":
              case "date":
                return (
                  <CRow key={key} className="py-2 justify-content-center">
                    <CCol lg={6} md={8} sm={12}>
                      <p className="field-label font-weight-bold">
                        {config.label}{" "}
                        {config.tooltip && (
                          <Tooltip
                            className="form-tooltip-icon"
                            text={config.tooltip}
                          />
                        )}
                      </p>
                      <CInput
                        type={config.type}
                        name={config.name}
                        placeholder={config.placeholder}
                        value={formData[key]}
                        onChange={handleChange(key)}
                        className={classNames({ "is-invalid": error })}
                      />
                      {error && (
                        <div className="text-danger pt-2">
                          <small>{error}</small>
                        </div>
                      )}
                    </CCol>
                  </CRow>
                )

              case "heading":
                return (
                  <CRow key={key} className="py-2 justify-content-center">
                    <CCol lg={6} md={8} sm={12}>
                      <br />
                      <br />
                      <p className="h3 field-label font-weight-bold">
                        {config.label}
                      </p>
                    </CCol>
                  </CRow>
                )

              // case "user-lookup":
              //   return (
              //     <CRow key={key} className="py-2 justify-content-center">
              //       <CCol lg={6} md={8} sm={12}>
              //         <p className="field-label font-weight-bold">
              //           {config.label}{" "}
              //           {config.tooltip && (
              //             <Tooltip className="form-tooltip-icon" text={config.tooltip} />
              //           )}
              //         </p>
              //         <UserSelectDropdown
              //             setState={(selectedOption) => handleChange(key)({ target: { value: selectedOption.value } })}
              //         />
              //         {error && <div className="text-danger pt-2"><small>{error}</small></div>}
              //       </CCol>
              //     </CRow>
              //   );

              case "t-and-c":
                return (
                  <CRow key={key} className="justify-content-center">
                    <CCol lg={6} md={8} sm={12}>
                      <CMSTermsAndConditionModal
                        show={showTermsAndConditionsModal}
                        setShow={setShowTermsAndConditionsModal}
                        setSelect={setIsTermsAndConditionsChecked}
                        resetStates={updateFormDataForTCsAcceptance}
                      />
                      {error && (
                        <div className="text-danger pt-2">
                          <small>{error}</small>
                        </div>
                      )}
                    </CCol>
                  </CRow>
                )

              case "textarea":
                return (
                  <CRow key={key} className="py-2 justify-content-center">
                    <CCol lg={6} md={8} sm={12}>
                      <p className="field-label font-weight-bold">
                        {config.label}{" "}
                        {config.tooltip && (
                          <Tooltip
                            className="form-tooltip-icon"
                            text={config.tooltip}
                          />
                        )}
                      </p>
                      <CTextarea
                        name={config.name}
                        placeholder={config.placeholder}
                        value={formData[key]}
                        onChange={handleChange(key)}
                        className={classNames({ "is-invalid": error })}
                      />
                      {error && (
                        <div className="text-danger pt-2">
                          <small>{error}</small>
                        </div>
                      )}
                    </CCol>
                  </CRow>
                )

              case "select":
                return (
                  <CRow key={key} className="py-2 justify-content-center">
                    <CCol lg={6} md={8} sm={12}>
                      <p className="field-label font-weight-bold">
                        {config.label}{" "}
                        {config.tooltip && (
                          <Tooltip
                            className="form-tooltip-icon"
                            text={config.tooltip}
                          />
                        )}
                      </p>
                      <Select
                        name={config.name}
                        options={config.options}
                        value={config.options.find(
                          (option) => option.value === formData[key]
                        )}
                        onChange={(selectedOption) =>
                          handleChange(key)({
                            target: { value: selectedOption.value }
                          })
                        }
                        className={classNames({ "is-invalid": error })}
                      />
                      {error && (
                        <div className="text-danger pt-2">
                          <small>{error}</small>
                        </div>
                      )}
                    </CCol>
                  </CRow>
                )

              case "radio":
                return (
                  <CRow key={key} className="py-2 justify-content-center">
                    <CCol lg={6} md={8} sm={12}>
                      <CFormGroup>
                        <CLabel className="field-label font-weight-bold">
                          {config.label}{" "}
                          {config.tooltip && (
                            <Tooltip
                              className="form-tooltip-icon"
                              text={config.tooltip}
                            />
                          )}
                        </CLabel>
                        <div>
                          {config.options.map((option) => (
                            <CFormGroup
                              variant="custom-radio"
                              inline
                              key={option.key}
                              className="custom-radio"
                            >
                              <CInputRadio
                                custom
                                id={`${key}-${option.value}`}
                                name={config.name}
                                value={option.value}
                                checked={formData[key] === option.value}
                                onChange={handleChange(key)}
                                className={classNames({ "is-invalid": error })}
                              />
                              <CLabel
                                variant="custom-checkbox"
                                htmlFor={`${key}-${option.value}`}
                              >
                                {option.label}
                              </CLabel>
                            </CFormGroup>
                          ))}
                        </div>

                        {key === "serviceLevel" && (
                          <>
                            {formData[key] === "fully_managed" && (
                              <div className="text-muted pt-2">
                                <small>
                                  Starting from £1,000 provisioning fee
                                  <br />
                                  Cloud Consumption: £0 - £999, starting from
                                  £1,500 monthly service fee
                                  <br />
                                  Cloud Consumption: £1,000 - £4,999, starting
                                  from £3,000 monthly service fee
                                  <br />
                                  Cloud Consumption: £5,000 and above, starting
                                  from £5,000 monthly service fee 
                                </small>
                              </div>
                            )}
                            {formData[key] === "co_managed" && (
                              <div className="text-muted pt-2">
                                <small>
                                  Starting from £1,000 provisioning fee
                                  <br />
                                  Cloud Consumption: £0 - £999, starting from
                                  £1,000 monthly service fee
                                  <br />
                                  Cloud Consumption: £1,000 - £4,999, starting
                                  from £2,000 monthly service fee
                                  <br />
                                  Cloud Consumption: £5,000 and above, starting
                                  from £3,000 monthly service fee
                                </small>
                              </div>
                            )}
                            {formData[key] === "self_managed" && (
                              <div className="text-muted pt-2">
                                <small>
                                  Flat fee
                                  <br />
                                  Starting from £1,000 provisioning fee
                                  <br />
                                  £500 per month compliance fee
                                  <br />
                                  £100 per ticket/request
                                </small>
                              </div>
                            )}
                          </>
                        )}
                      </CFormGroup>
                    </CCol>
                  </CRow>
                )

              case "checkbox":
                return (
                  <CRow key={key} className="py-2 justify-content-center">
                    <CCol lg={6} md={8} sm={12}>
                      <CFormGroup row className={config.className}>
                        <CCol xs="1" className="d-flex align-items-center">
                          <CInput
                            style={{ transform: "scale(0.6)" }}
                            type="checkbox"
                            name={config.name}
                            checked={formData[key]}
                            onChange={handleChange(key)}
                            className={classNames({ "is-invalid": error })}
                          />
                        </CCol>
                        <CCol>
                          <CLabel className="field-label font-weight-bold mb-0">
                            {config.label}{" "}
                            {config.tooltip && (
                              <Tooltip
                                className="form-tooltip-icon"
                                text={config.tooltip}
                              />
                            )}
                          </CLabel>
                        </CCol>
                      </CFormGroup>
                      {error && (
                        <div className="text-danger pt-2">
                          <small>{error}</small>
                        </div>
                      )}
                    </CCol>
                  </CRow>
                )

              case "optional-date":
                return (
                  <CRow key={key} className="py-2 justify-content-center">
                    <CCol lg={6} md={8} sm={12}>
                      <p className="field-label font-weight-bold">
                        {config.label}{" "}
                        {config.tooltip && (
                          <Tooltip
                            className="form-tooltip-icon"
                            text={config.tooltip}
                          />
                        )}
                      </p>
                      {config.options.map((option) => (
                        <CFormGroup
                          variant="custom-radio"
                          inline
                          key={option.key}
                          className="custom-radio"
                        >
                          <CInputRadio
                            custom
                            id={`${key}-${option.value}`}
                            name={`${key}Selection`} // Separate name for radio buttons
                            value={option.value}
                            checked={
                              formData[`${key}Selection`] === option.value
                            } // Check against a separate key
                            onChange={handleChange(`${key}Selection`)} // Update a separate key in state
                            className={classNames({ "is-invalid": error })}
                          />
                          <CLabel
                            variant="custom-checkbox"
                            htmlFor={`${key}-${option.value}`}
                          >
                            {option.label}
                          </CLabel>
                        </CFormGroup>
                      ))}

                      {formData[`${key}Selection`] === "yes" && (
                        <CInput
                        type="date"
                        name={config.name}
                        placeholder={config.placeholder}
                        value={formData[key]}
                        onChange={handleChange(key)}
                        className={classNames({ "is-invalid": error })}
                        />
                      )}
                      {error && (
                        <div className="text-danger pt-2">
                          <small>{error}</small>
                        </div>
                      )}
                    </CCol>
                  </CRow>
                )

              case "optional-text":
                return (
                  <CRow key={key} className="py-2 justify-content-center">
                    <CCol lg={6} md={8} sm={12}>
                      <p className="field-label font-weight-bold">
                        {config.label}{" "}
                        {config.tooltip && (
                          <Tooltip
                            className="form-tooltip-icon"
                            text={config.tooltip}
                          />
                        )}
                      </p>
                      {config.options.map((option) => (
                        <CFormGroup
                          variant="custom-radio"
                          inline
                          key={option.key}
                          className="custom-radio"
                        >
                          <CInputRadio
                            custom
                            id={`${key}-${option.value}`}
                            name={`${key}Selection`} // Separate name for radio buttons
                            value={option.value}
                            checked={
                              formData[`${key}Selection`] === option.value
                            } // Check against a separate key
                            onChange={handleChange(`${key}Selection`)} // Update a separate key in state
                            className={classNames({ "is-invalid": error })}
                          />
                          <CLabel
                            variant="custom-checkbox"
                            htmlFor={`${key}-${option.value}`}
                          >
                            {option.label}
                          </CLabel>
                        </CFormGroup>
                      ))}
                      {/* Conditionally render the date input */}
                      {formData[`${key}Selection`] === "yes" && (
                        <CInput
                          type="text"
                          name={config.name}
                          placeholder={config.placeholder}
                          value={formData[key]}
                          onChange={handleChange(key)}
                          className={classNames({ "is-invalid": error })}
                        />
                      )}
                      {error && (
                        <div className="text-danger pt-2">
                          <small>{error}</small>
                        </div>
                      )}
                    </CCol>
                  </CRow>
                )

              default:
                return null
            }
          })}
        </CContainer>

        <CRow className="justify-content-center m-4">
          <CCol md={{ span: 6, offset: 2 }} className="mr-n5">
            <FormActions onSubmit={handleFormSubmit} onCancel={handleFormCancel} />
          </CCol>
        </CRow>
      </LoadingSpinnerOverlay>
    </CContainer>
  );
}