import React, { useContext, useRef, useState, useEffect } from "react";
import ReactDOM from "react-dom";
import {
  CCol,
  CInput,
  CRow,
  CTextarea,
  CCard,
  CCardBody,
  CCardHeader,
  CFormGroup,
} from "@coreui/react";
import classNames from "classnames";
import ToasterProvider from "components/common/Context/ToasterContext";
import useNotifications from "components/common/customHooks/useNotifications";
import { OptionDropdown } from "components/common/dropdown";
import { FileAttachment } from "components/common/forms";
import { LoadingSpinnerOverlay } from "components/common/loading";
import { ConfirmModal } from "components/common/modal";
import { postServiceRequest } from "extensions/usoc/api/service";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { checkIfFileSupported, useExtension } from "utils";
import { useUserAuth } from "utils/hooks";
import FormActions from "./FormActions";

import { FiUsers } from "react-icons/fi";
import { GrDocumentVerified } from "react-icons/gr";
import { PiCertificate } from "react-icons/pi";
import { FaQuestionCircle } from "react-icons/fa";
import { ServiceCard } from "../../commonComponent";

import serviceData from "./ServiceData";

import "./NewRequestServiceForm.css";

const requestForms = [
  {
    name: "Advisory Request",
    description:
      "Get advice on Governance and Risk/Compliance, Billing, Optimisation, and Automation",
    icon: <GrDocumentVerified style={{ fontSize: "2.0rem" }} />,
  },
  {
    name: "User Access Management",
    description:
      "User and Group management for tools including Okta, VPN, and OpenCloud Portal",
    icon: <FiUsers style={{ fontSize: "1.5rem" }} />,
  },
  {
    name: "Service Modification",
    description:
      "Raise requests for on and off-boarding accounts and any modifications to your infrastructure",
    icon: <PiCertificate style={{ fontSize: "2.0rem" }} />,
  },
  {
    name: "Other",
    description:
      "You can use this form to raise a request if you cannot find the suitable request type in the other forms. Please note that non-standard requests may be subject to additional charges.",
    icon: <FaQuestionCircle style={{ fontSize: "1.5rem" }} />,
  },
];

const urgencyOptions = [
  { text: "High", value: "1", key: "1" },
  { text: "Medium", value: "2", key: "2" },
  { text: "Low", value: "3", key: "3" },
];

const serviceTiers = ["Self Managed", "Co-Managed", "Fully Managed"];

export default function NewRequestServiceForm() {
  const auth = useUserAuth();
  const history = useHistory();
  const enqueueToast = useContext(ToasterProvider);
  const extension = useExtension();
  const fileInputRef = useRef(null);
  const host = extension?.config?.env?.clientApiGateway?.URL;

  const [selectedRequestForm, setSelectedRequestForm] = useState(null);
  const [account, setAccount] = useState("");
  const [urgency, setUrgency] = useState("3");
  const [shortDescription, setShortDescription] = useState("");
  const [longDescription, setLongDescription] = useState("");

  const [serviceTier, setServiceTier] = useState("Self Managed");
  const [serviceRequestCategory, setServiceRequestCategory] = useState("");
  const [serviceRequestType, setServiceRequestType] = useState("");

  const [showModal, setShowModal] = useState(false);
  const [createdSrNumber, setCreatedSrNumber] = useState("");

  const [isServiceTierTouched, setIsServiceTierTouched] = useState(false);
  const [isServiceRequestCategoryTouched, setIsServiceRequestCategoryTouched] =
    useState(false);
  const [isServiceRequestTypeTouched, setIsServiceRequestTypeTouched] =
    useState(false);
  const [isAccountTouched, setIsAccountTouched] = useState(false);
  const [isUrgencyTouched, setIsUrgencyTouched] = useState(false);
  const [isShortDescriptionTouched, setIsShortDescriptionTouched] =
    useState(false);
  const [isLongDescriptionTouched, setIsLongDescriptionTouched] = useState(false);
  const [uploadFile, setUploadFile] = useState([]);
  const [isUploadFileTouched, setIsUploadFileTouched] = useState(false);

  const [notifications, setNotifications] = useNotifications();
  const [isLoading, setIsLoading] = useState(false);

  const projectID = useSelector((state) => state.settings.currentProject.id);

  const accounts = useSelector((state) => {
    return state.user.accounts?.account_attribute || [];
  });

  const applicableCategories =
    selectedRequestForm && selectedRequestForm !== "Other"
      ? serviceData
          .filter(
            (category) =>
              category.requestForm === selectedRequestForm &&
              category.types.some((type) => type.appliesTo[serviceTier])
          )
          .map((category) => ({
            text: category.category,
            key: category.category,
          }))
      : [];

  const selectedCategory = serviceData.find(
    (category) => category.category === serviceRequestCategory
  );

  const applicableTypes =
    selectedCategory && selectedRequestForm !== "Other"
      ? selectedCategory.types
          .filter((type) => type.appliesTo[serviceTier])
          .map((type) => ({
            text: type.name,
            key: type.name,
          }))
      : [];

  const selectedType =
    selectedCategory && selectedRequestForm !== "Other"
      ? selectedCategory.types.find((type) => type.name === serviceRequestType)
      : null;

  useEffect(() => {
    if (applicableCategories.length === 1) {
      const onlyCategory = applicableCategories[0].key;
      if (serviceRequestCategory !== onlyCategory) {
        setServiceRequestCategory(onlyCategory);
        setIsServiceRequestCategoryTouched(true);
        setServiceRequestType("");
      }
    } else if (applicableCategories.length === 0) {
      setServiceRequestCategory("");
      setServiceRequestType("");
    }
  }, [applicableCategories, serviceRequestCategory]);

  const onOk = () => {
    history.push({
      pathname: "/service",
      search: `?service_number=${createdSrNumber}`,
    });
  };

  const postUploadFile = async (fileDataURL) => {
    const promises = [];
    for (const data of fileDataURL.presigned_urls) {
      const file = uploadFile.find((item) => item.name === data.filename);
      if (!file) continue;

      const options = {
        method: "PUT",
        body: file,
        mode: "cors",
        headers: {
          "Content-Type": file.type || "application/octet-stream",
        },
      };
      promises.push(
        fetch(data.url, options).catch((err) => {
          throw err;
        })
      );
    }
    const results = await Promise.all(promises);
    return results;
  };

  const handleSubmit = async () => {
    setIsServiceTierTouched(true);
    setIsServiceRequestCategoryTouched(true);
    setIsServiceRequestTypeTouched(true);
    setIsAccountTouched(true);
    setIsUrgencyTouched(true);
    setIsShortDescriptionTouched(true);
    setIsLongDescriptionTouched(true);
    setIsUploadFileTouched(true);

    if (
      !account ||
      !urgency ||
      !shortDescription ||
      !longDescription ||
      !checkIfFileSupported(uploadFile)
    ) {
      enqueueToast(
        "failure",
        null,
        "Please fill all required fields and ensure files are supported."
      );
      return;
    }

    setIsLoading(true);

    const serviceRequestBody = {
      account_id: account,
      urgency,
      short_description: shortDescription,
      description: longDescription,
      attachments: uploadFile ? uploadFile.map((data) => data.name) : [],
      service_tier: serviceTier,
      service_request_category:
        selectedRequestForm === "Other" ? "Other" : serviceRequestCategory,
      service_request_type:
        selectedRequestForm === "Other" ? "Other" : serviceRequestType,
    };

    const response = await postServiceRequest(
      { auth, host, body: JSON.stringify(serviceRequestBody) },
      projectID
    );

    if (!response.error && response.data) {
      try {
        await postUploadFile(response.data);
        const ticketNumber = response?.data?.ticket_number;
        if (ticketNumber) {
          const currentDate = new Date();
          const notification = {
            id: notifications.length + 1,
            from: "Created Successfully!!",
            active: true,
            approved: true,
            ticket_number: response.data.ticket_number,
            time: `${currentDate.toDateString()} ${currentDate.toLocaleTimeString()}`,
            description: "Service requested successfully with number ",
            requestNumber: ["ServiceRequest", response.data.ticket_number],
          };
          setNotifications([notification, ...notifications]);
          setCreatedSrNumber(ticketNumber);
        }
        setShowModal(true);
      } catch (err) {
        setIsLoading(false);
        enqueueToast(
          "failure",
          "failed",
          "Failed to upload the File. Please try again later."
        );
      }
      setAccount("");
      setUrgency("3");
      setShortDescription("");
      setLongDescription("");
      setIsLongDescriptionTouched(false);
      setIsShortDescriptionTouched(false);
      setUploadFile([]);
      setIsUploadFileTouched(false);
      setServiceRequestCategory("");
      setServiceRequestType("");
      setIsServiceTierTouched(false);
      setIsServiceRequestCategoryTouched(false);
      setIsServiceRequestTypeTouched(false);
      setIsAccountTouched(false);
      setIsUrgencyTouched(false);
    } else {
      enqueueToast("failure", null, "Could not request this service");
      history.goBack();
    }
    setIsLoading(false);
  };

  const handleCancel = () => {
    history.goBack();
  };

  const handleCategoryChange = (e) => {
    const value = e.target.value;
    setServiceRequestCategory(value);
    setIsServiceRequestCategoryTouched(true);
    setServiceRequestType("");
  };

  return (
    <div className="service-request-container">
      <LoadingSpinnerOverlay isLoading={isLoading} spinnerSize="8rem">
        <CRow className="m-4 justify-content-center">
          {requestForms.map((form) => (
            <CCol
              key={form.name}
              xs="12"
              sm="6"
              md="3"
              className="mb-4 d-flex justify-content-center"
            >
              <ServiceCard
                value={form.name}
                setState={(value) => {
                  setSelectedRequestForm(value);
                  setServiceRequestCategory("");
                  setServiceRequestType("");
                }}
                title={form.name}
                imageIcon={form.icon}
                active={true}
                customIconClass="small-icon"
                customTextClass="tight-spacing small-text"
              />
            </CCol>
          ))}
        </CRow>

        {selectedRequestForm && (
          <CRow className="m-2">
            <CCol md="12">
              <p className="srvreq-heading py-2">
                {selectedRequestForm === "Other"
                  ? "Other / Non-Standard Request"
                  : selectedRequestForm}
              </p>
              <p className="srvreq-sub-heading">
                {
                  requestForms.find((form) => form.name === selectedRequestForm)
                    ?.description
                }
              </p>
            </CCol>
          </CRow>
        )}

        {selectedRequestForm && (
          <>
            {selectedRequestForm !== "Other" && (
              <CRow className="mx-2 my-4">
                <CCol xs="12" md="6">
                  <CCard className="srvreq-demo-card">
                    <CCardHeader className="srvreq-demo-card-header">
                      <strong>Service Tier (Demo Only)</strong>
                    </CCardHeader>
                    <CCardBody>
                      <OptionDropdown
                        items={serviceTiers.map((tier) => ({
                          text: tier,
                          key: tier,
                        }))}
                        value={serviceTier}
                        onChange={(value) => {
                          setServiceTier(value);
                          setServiceRequestCategory("");
                          setServiceRequestType("");
                        }}
                        className="w-100"
                      />
                    </CCardBody>
                  </CCard>
                </CCol>
              </CRow>
            )}

            {selectedRequestForm !== "Other" &&
              applicableCategories.length > 1 && (
                <CRow className="mx-2 my-4">
                  <CCol xs="12" md="6">
                    <p className="srvreq-field-label font-weight-bold">
                      Service Request Category *
                    </p>
                    <CFormGroup className="srvreq-custom-radio-group">
                      {applicableCategories.map((category) => (
                        <div key={category.key} className="srvreq-radio-option">
                          <input
                            type="radio"
                            id={`category-${category.key}`}
                            name="serviceRequestCategory"
                            value={category.key}
                            checked={serviceRequestCategory === category.key}
                            onChange={handleCategoryChange}
                          />
                          <label htmlFor={`category-${category.key}`}>
                            {category.text}
                          </label>
                        </div>
                      ))}
                    </CFormGroup>
                    {isServiceRequestCategoryTouched &&
                      !serviceRequestCategory && (
                        <div className="srvreq-invalid-feedback d-block">
                          This field is required.
                        </div>
                      )}
                  </CCol>
                </CRow>
              )}

            {selectedRequestForm !== "Other" && (
              <CRow className="mx-2 my-4">
                <CCol xs="12" md="6">
                  <p className="srvreq-field-label font-weight-bold">
                    Service Request Type *
                  </p>
                  <OptionDropdown
                    items={
                      serviceRequestCategory
                        ? applicableTypes
                        : [
                            {
                              text: "Select Request Category to continue",
                              key: "",
                            },
                          ]
                    }
                    value={serviceRequestType}
                    onChange={(value) => setServiceRequestType(value)}
                    className={classNames("w-100", {
                      "is-invalid":
                        isServiceRequestTypeTouched && !serviceRequestType,
                    })}
                    placeholder={
                      serviceRequestCategory
                        ? "Select Service Request Type"
                        : "Select Request Category to continue"
                    }
                    disabled={!serviceRequestCategory}
                  />
                  {isServiceRequestTypeTouched && !serviceRequestType && (
                    <div className="srvreq-invalid-feedback d-block">
                      This field is required.
                    </div>
                  )}
                </CCol>
              </CRow>
            )}

            <CRow className="mx-2 my-4">
              <CCol xs="12" md="6">
                <p className="srvreq-field-label font-weight-bold">
                  Cloud Service Account *
                </p>
                <OptionDropdown
                  items={[
                    { text: "Not Sure", key: "" },
                    ...(accounts
                      ? accounts.map(
                          ({
                            account_number: accountNumber,
                            account_environment: accountEnvironment,
                            account_name: accountName,
                          }) => ({
                            text: [
                              accountEnvironment,
                              accountName,
                              accountNumber,
                            ]
                              .filter(Boolean)
                              .join(" | "),
                            key: accountNumber,
                          })
                        )
                      : []),
                  ]}
                  value={account}
                  onChange={(value) => {
                    setAccount(value);
                    setIsAccountTouched(true);
                  }}
                  className={classNames("w-100", {
                    "is-invalid": isAccountTouched && !account,
                  })}
                  buttonClassName="w-100"
                />
                {isAccountTouched && !account && (
                  <div className="srvreq-invalid-feedback d-block">
                    This field is required.
                  </div>
                )}
              </CCol>
              <CCol xs="12" md="6">
                <p className="srvreq-field-label font-weight-bold">Urgency *</p>
                <OptionDropdown
                  items={urgencyOptions}
                  value={urgency}
                  onChange={(value) => {
                    setUrgency(value);
                    setIsUrgencyTouched(true);
                  }}
                  className={classNames("w-100", {
                    "is-invalid": isUrgencyTouched && !urgency,
                  })}
                  buttonClassName="w-100"
                />
                {isUrgencyTouched && !urgency && (
                  <div className="srvreq-invalid-feedback d-block">
                    This field is required.
                  </div>
                )}
              </CCol>
            </CRow>

            <CRow className="mx-2 my-4">
              <CCol xs="12" md="6">
                <p className="srvreq-field-label font-weight-bold">
                  Short Description *
                </p>
                <CInput
                  type="text"
                  name="issue"
                  placeholder="Please Describe your Issue"
                  value={shortDescription}
                  onChange={(e) => {
                    setIsShortDescriptionTouched(true);
                    setShortDescription(e.target.value);
                  }}
                  className={classNames({
                    "is-invalid":
                      isShortDescriptionTouched && !shortDescription,
                  })}
                />
                {isShortDescriptionTouched && !shortDescription && (
                  <div className="srvreq-invalid-feedback">
                    This field is required.
                  </div>
                )}
              </CCol>
            </CRow>

            <CRow className="mx-2 my-4">
              <CCol xs="12" md="6">
                <p className="srvreq-field-label font-weight-bold">
                  Full Description *
                </p>
                <CTextarea
                  type="text"
                  name="issue"
                  placeholder="Please Describe your Issue"
                  value={longDescription}
                  onChange={(e) => {
                    setIsLongDescriptionTouched(true);
                    setLongDescription(e.target.value);
                  }}
                  rows={10}
                  className={classNames({
                    "is-invalid": isLongDescriptionTouched && !longDescription,
                  })}
                />
                {isLongDescriptionTouched && !longDescription && (
                  <div className="srvreq-invalid-feedback">
                    This field is required.
                  </div>
                )}
              </CCol>
            </CRow>

            <CRow className="mx-2 my-4">
              <FileAttachment
                uploadFile={uploadFile}
                isUploadFileTouched={isUploadFileTouched}
                setUploadFile={setUploadFile}
                setIsUploadFileTouched={setIsUploadFileTouched}
                fileInputRef={fileInputRef}
              />
              {isUploadFileTouched && !checkIfFileSupported(uploadFile) && (
                <CCol xs="12" md="6">
                  <div className="srvreq-invalid-feedback d-block">
                    Please ensure files are supported.
                  </div>
                </CCol>
              )}
            </CRow>

            {selectedType && selectedRequestForm !== "Other" && (
              <CRow className="mx-2 my-4">
                <CCol xs="12" md="6">
                  <p className="srvreq-field-label font-weight-bold">
                    Charging State: {selectedType.chargingState}
                  </p>
                </CCol>
              </CRow>
            )}

            <FormActions onSubmit={handleSubmit} onCancel={handleCancel} />
          </>
        )}
      </LoadingSpinnerOverlay>

      {ReactDOM.createPortal(
        <ConfirmModal
          show={showModal}
          setShow={setShowModal}
          message={`Service requested successfully${
            createdSrNumber ? ` with number ${createdSrNumber}` : ""
          }`}
          onCancel={handleCancel}
          onOkay={onOk}
          okayBtnText="View Service Request"
        />,
        document.getElementById("status-modal")
      )}
    </div>
  );
}
