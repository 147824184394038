import { CContainer, CCol, CInput, CRow, CTextarea, CFormGroup, CLabel, CInputRadio } from "@coreui/react"
import classNames from "classnames"
import ToasterProvider from "components/common/Context/ToasterContext"
import { LoadingSpinnerOverlay } from "components/common/loading"
import React, { useContext, useState } from "react"
import { useSelector } from "react-redux"
import { useHistory } from "react-router-dom"
import { useExtension } from "utils"
import { useUserAuth } from "utils/hooks"
import Joi from "joi"
import Select from "react-select"
import RequestSuccess from "./components/RequestSuccess"
import { Tooltip } from "components/common/tooltip"
import FormActions from "./FormActions"
import { postCMSEnquiry } from "extensions/usoc/api/service"
import "./RequestStyles.scss"

const formInputConfig = {
  requestedBy: {
    name: "requestedBy",
    label: "Requested By",
    type: "text",
    placeholder: "Your email address",
    tooltip: "This field is automatically populated with your email address.",
    readOnly: true
  },
  assetProjectName: {
    name: "assetProjectName",
    label: "Asset / Project Name",
    type: "text",
    placeholder: "Enter the name of your asset or project",
    tooltip: "Enter the name of your asset or project."
  },
  description: {
    name: "description",
    label: "Brief Description",
    type: "textarea",
    placeholder: "Provide a brief description of your project",
    tooltip: "Provide a brief description of your project.",
    rows: 3
  },
  projectType: {
    name: "projectType",
    label: "Project Type",
    type: "select",
    placeholder: "Select project type",
    tooltip: "Select the type of project you are working on.",
    options: [
      { label: "Select...", value: "", key: "0" },
      { label: "Research and Development (R&D)", value: "Research and Development (R&D)", key: "1" },
      { label: "Prototype", value: "Prototype", key: "2" },
      { label: "POC", value: "POC", key: "3" },
      { label: "Asset", value: "Asset", key: "4" },
      { label: "Accelerator", value: "Accelerator", key: "5" },
      { label: "Bespoke Client Engagement", value: "Bespoke Client Engagement", key: "6" },
      { label: "Other", value: "Other", key: "7" }
    ]
  },
  readiness: {
    name: "readiness",
    label: "Readiness",
    type: "select",
    placeholder: "Select readiness stage",
    tooltip: "Indicate the readiness stage of your project.",
    options: [
      { label: "Select...", value: "", key: "0" },
      { label: "N/A", value: "N/A", key: "1" },
      { label: "Market Ready", value: "Market Ready", key: "2" },
      { label: "In-Development", value: "In-Development", key: "3" },
      { label: "Pre-Development", value: "Pre-Development", key: "4" }
    ]
  },
  compliance: {
    name: "assurance",
    label: "Deloitte Compliance",
    type: "select",
    placeholder: "Select compliance status",
    tooltip: "Select the compliance status for your project.",
    options: [
      { label: "Select...", value: "", key: "0" },
      { label: "Compliance Process already Started (GTOM, INFOSEC, SSDLC)", value: "Compliance Process already Started", key: "1" },
      { label: "Not Started Yet", value: "Not Started Yet", key: "2" }
    ]
  },
  offeringPortfolio: {
    name: "offeringPortfolio",
    label: "Business Function",
    type: "select",
    placeholder: "Select the Business Function",
    tooltip: "Select the Fucntion the project sits in (previoulsy 'Service Line').",
    options: [
      { label: "Select...", value: "", key: "0" },
      { label: "Tax & Legal", value: "TL", key: "1" },
      { label: "Audit & Assurance", value: "AA", key: "2" },
      { label: "Strategy, Risk & Transactions​", value: "SRT", key: "3" },
      { label: "Technology & Transformation​", value: "TT", key: "4" },
      { label: "ITS​", value: "ITS​", key: "5" },
      { label: "Enabling Functions​", value: "ENA", key: "6" }
    ]
  },
  industry: {
    name: "industry",
    label: "Industry",
    type: "select",
    placeholder: "Select industry",
    tooltip: "Select the industry relevant to your project.",
    options: [
      { label: "Select...", value: "", key: "0" },
      { label: "Financial Services", value: "Financial Services", key: "1" },
      { label: "Consumer", value: "Consumer", key: "2" },
      { label: "Energy, Resources & Industrials", value: "Energy, Resources & Industrials", key: "3" },
      { label: "Government & Public Services", value: "Government & Public Services", key: "4" },
      { label: "Life Sciences & Health Care", value: "Life Sciences & Health Care", key: "5" },
      { label: "Technology, Media & Telecommunications", value: "Technology, Media & Telecommunications", key: "6" },
      { label: "Private Equity", value: "Private Equity", key: "7" },
      { label: "Cross Sector", value: "Cross Sector", key: "8" },
      { label: "Other", value: "Other", key: "9" }
    ]
  },
  deloitteTeam: {
    name: "studio",
    label: "Deloitte Team",
    type: "text",
    placeholder: "Enter the name of your Deloitte Team",
    tooltip: "Which team is going to be responsible for this project?",
    readOnly: false
  },
  cloudServiceProvider: {
    name: "cloudServiceProvider",
    label: "Cloud Service Provider",
    type: "select",
    tooltip: "Select a cloud service providers.",
    options: [
      { label: "AWS", value: "AWS", key: "1" },
      { label: "Azure", value: "Azure", key: "2" },
      { label: "GCP", value: "GCP", key: "3" }
    ]
  },
  environmentTypes: {
    name: "workloadEnvironmentTypes",
    label: "Environment Types",
    type: "checkboxGroup",
    tooltip: "Select one or more environment types for your workload. Note: Selecting 'Prod' includes Non-Prod environments by default.",
    options: [
      { label: "Non-Prod", value: "Non-Prod", key: "1" },
      { label: "Prod", value: "Prod", key: "2" }
    ]
  },
  buildHelp: {
    name: "buildHelp",
    label: "Do you require build help for your cloud environments?",
    type: "radio",
    tooltip: "Do you require any Cloud Engineering assistance in building and/or deploying your environment?",
    options: [
      { label: "Yes", value: "Yes", key: "1" },
      { label: "No", value: "No", key: "2" }
    ]
  }
}

const inputSchema = Joi.object({
  requestedBy: Joi.string().required().messages({
    "any.required": "Cannot be an empty field",
    "string.empty": "Cannot be an empty field"
  }),
  assetProjectName: Joi.string().required().messages({
    "any.required": "Cannot be an empty field",
    "string.empty": "Cannot be an empty field"
  }),
  description: Joi.string().required().messages({
    "any.required": "Cannot be an empty field",
    "string.empty": "Cannot be an empty field"
  }),
  projectType: Joi.string().required().messages({
    "any.required": "Cannot be an empty field",
    "string.empty": "Cannot be an empty field"
  }),
  readiness: Joi.string().required().messages({
    "any.required": "Cannot be an empty field",
    "string.empty": "Cannot be an empty field"
  }),
  compliance: Joi.string().required().messages({
    "any.required": "Cannot be an empty field",
    "string.empty": "Cannot be an empty field"
  }),
  offeringPortfolio: Joi.string().required().messages({
    "any.required": "Cannot be an empty field",
    "string.empty": "Cannot be an empty field"
  }),
  industry: Joi.string().required().messages({
    "any.required": "Cannot be an empty field",
    "string.empty": "Cannot be an empty field"
  }),
  deloitteTeam: Joi.string().required().messages({
    "any.required": "Cannot be an empty field",
    "string.empty": "Cannot be an empty field"
  }),
  cloudServiceProvider: Joi.string().required().messages({
    "any.required": "Cannot be an empty field",
    "array.min": "A Cloud Service Provider must be selected"
  }),
  environmentTypes: Joi.array().min(1).required().messages({
    "any.required": "Cannot be an empty field",
    "array.min": "At least one Environment Type must be selected"
  }),
  buildHelp: Joi.string().required().messages({
    "any.required": "Cannot be an empty field",
    "string.empty": "Cannot be an empty field"
  })
});

const validateField = (field, value) => {
  return inputSchema.extract(field).validate(value, { abortEarly: false });
}

export default function ModalCloudHostingForm() {

  const auth = useUserAuth();
  const extension = useExtension()
  const host = extension?.config?.env?.clientApiGateway?.URL
  const projectID = useSelector((state) => state.settings.currentProject.id)
  const history = useHistory()
  const enqueueToast = useContext(ToasterProvider);

  const [requestedBy, setRequestedBy] = useState(auth.email);
  const [requestedByError, setRequestedByError] = useState("");

  const [assetProjectName, setAssetProjectName] = useState("");
  const [assetProjectNameError, setAssetProjectNameError] = useState("");

  const [description, setDescription] = useState("");
  const [descriptionError, setDescriptionError] = useState("");

  const [projectType, setProjectType] = useState("");
  const [projectTypeError, setProjectTypeError] = useState("");

  const [readiness, setReadiness] = useState("");
  const [readinessError, setReadinessError] = useState("");

  const [compliance, setCompliance] = useState("");
  const [complianceError, setComplianceError] = useState("");

  const [offeringPortfolio, setOfferingPortfolio] = useState("");
  const [offeringPortfolioError, setOfferingPortfolioError] = useState("");

  const [industry, setIndustry] = useState("");
  const [industryError, setIndustryError] = useState("");

  const [deloitteTeam, setDeloitteTeam] = useState("");
  const [deloitteTeamError, setDeloitteTeamError] = useState("");

  const [cloudServiceProvider, setCloudServiceProvider] = useState("");
  const [cloudServiceProviderError, setCloudServiceProviderError] = useState("");

  const [environmentTypes, setEnvironmentTypes] = useState([]);
  const [environmentTypesError, setEnvironmentTypesError] = useState("");

  const [buildHelp, setBuildHelp] = useState("");
  const [buildHelpError, setBuildHelpError] = useState("");

  const [isLoading, setIsLoading] = useState(false);
  const [formSubmitted, setFormSubmitted] = useState(false);
  const [requestNumber, setRequestNumber] = useState("")

  const resetFormInput = () => {
    setAssetProjectName("");
    setDescription("");
    setProjectType("");
    setReadiness("");
    setCompliance("");
    setOfferingPortfolio("");
    setIndustry("");
    setDeloitteTeam("");
    setCloudServiceProvider("");
    setEnvironmentTypes([]);
    setBuildHelp("");
  }

  const resetFormError = () => {
    setAssetProjectNameError("");
    setDescriptionError("");
    setProjectTypeError("");
    setReadinessError("");
    setComplianceError("");
    setOfferingPortfolioError("");
    setIndustryError("");
    setDeloitteTeamError("");
    setCloudServiceProviderError("");
    setEnvironmentTypesError("");
    setBuildHelpError("");
  }

  const setFormError = (field, error) => {
    switch (field) {
      case "assetProjectName":
        setAssetProjectNameError(error);
        break;
      case "description":
        setDescriptionError(error);
        break;
      case "projectType":
        setProjectTypeError(error);
        break;
      case "readiness":
        setReadinessError(error);
        break;
      case "compliance":
        setComplianceError(error);
        break;
      case "offeringPortfolio":
        setOfferingPortfolioError(error);
        break;
      case "industry":
        setIndustryError(error);
        break;
      case "deloitteTeam":
        setDeloitteTeamError(error);
        break;
      case "cloudServiceProvider":
        setCloudServiceProviderError(error);
        break;
      case "environmentTypes":
        setEnvironmentTypesError(error);
        break;
      case "buildHelp":
        setBuildHelpError(error);
        break;
      default:
        break;
    }
  }

  const handleProjectTypeChange = (item) => {
    setProjectType(item.value);
    const { error } = validateField('projectType', item.value);
    if (error) {
      error.details.forEach((eachError) => {
        setProjectTypeError(eachError.message);
      });
    } else {
      setProjectTypeError("");
    }
  }

  const handleReadinessChange = (item) => {
    setReadiness(item.value);
    const { error } = validateField('readiness', item.value);
    if (error) {
      error.details.forEach((eachError) => {
        setReadinessError(eachError.message);
      });
    } else {
      setReadinessError("");
    }
  }

  const handleComplianceChange = (item) => {
    setCompliance(item.value);
    const { error } = validateField('compliance', item.value);
    if (error) {
      error.details.forEach((eachError) => {
        setComplianceError(eachError.message);
      });
    } else {
      setComplianceError("");
    }
  }

  const handleOfferingPortfolioChange = (item) => {
    setOfferingPortfolio(item.value);
    const { error } = validateField('offeringPortfolio', item.value);
    if (error) {
      error.details.forEach((eachError) => {
        setOfferingPortfolioError(eachError.message);
      });
    } else {
      setOfferingPortfolioError("");
    }
  }

  const handleIndustryChange = (item) => {
    setIndustry(item.value);
    const { error } = validateField('industry', item.value);
    if (error) {
      error.details.forEach((eachError) => {
        setIndustryError(eachError.message);
      });
    } else {
      setIndustryError("");
    }
  }

  const handleDeloitteTeamChange = (item) => {
    setDeloitteTeam(item.target.value);
    const { error } = validateField('deloitteTeam', item.target.value);
    if (error) {
      error.details.forEach((eachError) => {
        setDeloitteTeamError(eachError.message);
      });
    } else {
      setDeloitteTeamError("");
    }
  }

  const handleAssetProjectNameChange = (e) => {
    setAssetProjectName(e.target.value);
    const { error } = validateField('assetProjectName', e.target.value);
    if (error) {
      error.details.forEach((eachError) => {
        setAssetProjectNameError(eachError.message);
      });
    } else {
      setAssetProjectNameError("");
    }
  }

  const handleDescriptionChange = (e) => {
    setDescription(e.target.value);
    const { error } = validateField('description', e.target.value);
    if (error) {
      error.details.forEach((eachError) => {
        setDescriptionError(eachError.message);
      });
    } else {
      setDescriptionError("");
    }
  }

  const handleCloudServiceProviderChange = (item) => {
    setCloudServiceProvider(item.value);
    const { error } = validateField('cloudServiceProvider', item.value);
    if (error) {
      error.details.forEach((eachError) => {
        setCloudServiceProviderError(eachError.message);
      });
    } else {
      setCloudServiceProviderError("");
    }
  }
  const handleEnvironmentTypesChange = (selectedOptions) => {
    const values = selectedOptions.map(option => option.value);
    setEnvironmentTypes(values);
    const { error } = validateField('environmentTypes', values);
    if (error) {
      error.details.forEach((eachError) => {
        setEnvironmentTypesError(eachError.message);
      });
    } else {
      setEnvironmentTypesError("");
    }
  }

  const handleBuildHelpChange = (e) => {
    setBuildHelp(e.target.value);
    const { error } = validateField('buildHelp', e.target.value);
    if (error) {
      error.details.forEach((eachError) => {
        setBuildHelpError(eachError.message);
      });
    } else {
      setBuildHelpError("");
    }
  }

  const handleFormSubmit = async () => {
    resetFormError();

    const { error } = await inputSchema.validate({
      requestedBy: requestedBy,
      assetProjectName: assetProjectName,
      description: description,
      projectType: projectType,
      readiness: readiness,
      compliance: compliance,
      offeringPortfolio: offeringPortfolio,
      industry: industry,
      deloitteTeam: deloitteTeam,
      cloudServiceProvider: cloudServiceProvider,
      environmentTypes: environmentTypes,
      buildHelp: buildHelp
    }, { abortEarly: false });

    if (error) {
      error.details.forEach((eachError) => {
        setFormError(eachError.path?.[0], eachError.message);
      })      

      const invalidFields = error.details.map(detail => detail.path[0]); // Assuming 'path[0]' gives the field name
      // Scroll to the first invalid field
      if (invalidFields.length > 0) {
        const firstInvalidField = document.querySelector(`[name="${invalidFields[0]}"]`); // Adjust the selector if needed
        if (firstInvalidField) {
          firstInvalidField.scrollIntoView({ behavior: 'smooth', block: 'center' }); // Smooth scroll to top of the field
        }
      }
    } else {
      setIsLoading(true);

      const INITIAL_ENQUIRY_EMAIL = "CMS Initial Enquiry"
      const requestBody = {
          short_description: `${INITIAL_ENQUIRY_EMAIL} - ${assetProjectName}`,
          cloudServiceProvider:cloudServiceProvider,
          description: `
              <p>${requestedBy} raised an enquiry. Below are the details.</p>
              <p>&nbsp;</p>
              <p><strong>Project info:</strong></p>
              <p>Name: ${assetProjectName}</p>
              <p>Description: ${description}</p>
              <p>Environment: ${environmentTypes}</p>
              <p>CSP: ${cloudServiceProvider}</p>
              <p>Type: ${projectType}</p>
              <p>Readiness: ${readiness}</p>
              <p>&nbsp;</p>
              <p>GTOM state: ${compliance}</p>
              <p>Offering Portfolio: ${offeringPortfolio}</p>
              <p>Industry: ${industry}</p>
              <p>&nbsp;</p>
              <p>Deloitte team: ${deloitteTeam}</p>
              <p>Require CMS build help: ${buildHelp}</p>
          `
      }
      const response = await postCMSEnquiry(
        {
          auth,
          host,
          body: JSON.stringify(requestBody)
        }, projectID
      )

      if (response.error) {
        enqueueToast(
          "failure",
          "New Customer Environment Request Failed",
          "Something went wrong... please try again later"
        )
      } else {
        setRequestNumber(response.data.ticket_number);
        setFormSubmitted(true);
        enqueueToast(
          "success",
          "New Customer Environment Request Successful",
          `Request Id: ${response.data.ticket_number}`
        )
      }
      setIsLoading(false)
    }
  }

  const handleFormCancel = () => {
    resetFormInput();
    resetFormError();
    history.go(-1);
  }

  if (formSubmitted) {
    return (
      <RequestSuccess requestNumber={requestNumber} />
    )
  }

  return (
    <CContainer className="service-request-container py-2">
      <LoadingSpinnerOverlay isLoading={isLoading} spinnerSize="8rem">
        <CRow className="m-2 justify-content-center">
          <CCol lg={6} md={8} sm={12}>
            <p className="heading py-2 text-center">
              Cloud Hosting Enquiry
            </p>
          </CCol>
        </CRow>
        <CRow className="m-2 justify-content-center">
          <CCol lg={6} md={8} sm={12}>
            <p className="sub-heading">
              Fill out the below form to enquire about Cloud Hosting with Deloitte Cloud Managed Services.
            </p>
          </CCol>
        </CRow>

        <CContainer className='m-2'>
          <CRow className='py-2 justify-content-center'>
            <CCol lg={6} md={8} sm={12}>
              <p className="field-label font-weight-bold">
                {formInputConfig.requestedBy.label}
                {" "}
                <Tooltip
                  className="form-tooltip-icon"
                  text={formInputConfig.requestedBy.tooltip}
                />
              </p>
              <CInput
                type={formInputConfig.requestedBy.type}
                name={formInputConfig.requestedBy.name}
                placeholder={formInputConfig.requestedBy.placeholder}
                readOnly={formInputConfig.requestedBy.readOnly}
                value={requestedBy}
                className={classNames({ "is-invalid": (requestedByError) })}
              />
              <div className="text-danger pt-2"><small>{requestedByError}</small></div>
            </CCol>
          </CRow>

          <CRow className='py-2 justify-content-center'>
            <CCol lg={6} md={8} sm={12}>
              <p className="field-label font-weight-bold">
                {formInputConfig.assetProjectName.label}
                {" "}
                <Tooltip
                  className="form-tooltip-icon"
                  text={formInputConfig.assetProjectName.tooltip}
                />
              </p>
              <CInput
                type={formInputConfig.assetProjectName.type}
                name={formInputConfig.assetProjectName.name}
                placeholder={formInputConfig.assetProjectName.placeholder}
                onChange={handleAssetProjectNameChange}
                className={classNames({ "is-invalid": (assetProjectNameError) })}
              />
              <div className="text-danger pt-2"><small>{assetProjectNameError}</small></div>
            </CCol>
          </CRow>

          <CRow className='py-2 justify-content-center'>
            <CCol lg={6} md={8} sm={12}>
              <p className="field-label font-weight-bold">
                {formInputConfig.description.label}
                {" "}
                <Tooltip
                  className="form-tooltip-icon"
                  text={formInputConfig.description.tooltip}
                />
              </p>
              <CTextarea
                style={{ height: "auto" }}
                rows={formInputConfig.description.rows}
                type={formInputConfig.description.type}
                name={formInputConfig.description.name}
                placeholder={formInputConfig.description.placeholder}
                onChange={handleDescriptionChange}
                className={classNames({ "is-invalid": (descriptionError) })}
              />
              <div className="text-danger pt-2"><small>{descriptionError}</small></div>
            </CCol>
          </CRow>

          <CRow className='py-2 justify-content-center'>
            <CCol lg={6} md={8} sm={12}>
              <p className="field-label font-weight-bold">
                {formInputConfig.projectType.label}
                {" "}
                <Tooltip
                  className="form-tooltip-icon"
                  text={formInputConfig.projectType.tooltip}
                />
              </p>
              <Select
                placeholder={formInputConfig.projectType.placeholder}
                options={formInputConfig.projectType.options}
                isSearchable={true}
                name={formInputConfig.projectType.name}
                onChange={handleProjectTypeChange}
              />
              <div className="text-danger pt-2"><small>{projectTypeError}</small></div>
            </CCol>
          </CRow>

          <CRow className='py-2 justify-content-center'>
            <CCol lg={6} md={8} sm={12}>
              <p className="field-label font-weight-bold">
                {formInputConfig.readiness.label}
                {" "}
                <Tooltip
                  className="form-tooltip-icon"
                  text={formInputConfig.readiness.tooltip}
                />
              </p>
              <Select
                placeholder={formInputConfig.readiness.placeholder}
                options={formInputConfig.readiness.options}
                isSearchable={true}
                name={formInputConfig.readiness.name}
                onChange={handleReadinessChange}
              />
              <div className="text-danger pt-2"><small>{readinessError}</small></div>
            </CCol>
          </CRow>

          <CRow className='py-2 justify-content-center'>
            <CCol lg={6} md={8} sm={12}>
              <p className="field-label font-weight-bold">
                {formInputConfig.compliance.label}
                {" "}
                <Tooltip
                  className="form-tooltip-icon"
                  text={formInputConfig.compliance.tooltip}
                />
              </p>
              <Select
                placeholder={formInputConfig.compliance.placeholder}
                options={formInputConfig.compliance.options}
                isSearchable={true}
                name={formInputConfig.compliance.name}
                onChange={handleComplianceChange}
              />
              <div className="text-danger pt-2"><small>{complianceError}</small></div>
            </CCol>
          </CRow>

          <CRow className='py-2 justify-content-center'>
            <CCol lg={6} md={8} sm={12}>
              <p className="field-label font-weight-bold">
                {formInputConfig.offeringPortfolio.label}
                {" "}
                <Tooltip
                  className="form-tooltip-icon"
                  text={formInputConfig.offeringPortfolio.tooltip}
                />
              </p>
              <Select
                placeholder={formInputConfig.offeringPortfolio.placeholder}
                options={formInputConfig.offeringPortfolio.options}
                isSearchable={true}
                name={formInputConfig.offeringPortfolio.name}
                onChange={handleOfferingPortfolioChange}
              />
              <div className="text-danger pt-2"><small>{offeringPortfolioError}</small></div>
            </CCol>
          </CRow>

          <CRow className='py-2 justify-content-center'>
            <CCol lg={6} md={8} sm={12}>
              <p className="field-label font-weight-bold">
                {formInputConfig.industry.label}
                {" "}
                <Tooltip
                  className="form-tooltip-icon"
                  text={formInputConfig.industry.tooltip}
                />
              </p>
              <Select
                placeholder={formInputConfig.industry.placeholder}
                options={formInputConfig.industry.options}
                isSearchable={true}
                name={formInputConfig.industry.name}
                onChange={handleIndustryChange}
              />
              <div className="text-danger pt-2"><small>{industryError}</small></div>
            </CCol>
          </CRow>

          <CRow className='py-2 justify-content-center'>
            <CCol lg={6} md={8} sm={12}>
              <p className="field-label font-weight-bold">
                {formInputConfig.deloitteTeam.label}
                {" "}
                <Tooltip
                  className="form-tooltip-icon"
                  text={formInputConfig.deloitteTeam.tooltip}
                />
              </p>
              <CTextarea
                style={{ height: "auto" }}
                rows={formInputConfig.deloitteTeam.rows}
                type={formInputConfig.deloitteTeam.type}
                name={formInputConfig.deloitteTeam.name}
                placeholder={formInputConfig.deloitteTeam.placeholder}
                onChange={handleDeloitteTeamChange}
                className={classNames({ "is-invalid": (deloitteTeamError) })}
              />
              <div className="text-danger pt-2"><small>{deloitteTeamError}</small></div>
            </CCol>
          </CRow>

          <CRow className='py-2 justify-content-center'>
            <CCol lg={6} md={8} sm={12}>
              <p className="field-label font-weight-bold">
                {formInputConfig.cloudServiceProvider.label}
                {" "}
                <Tooltip
                  className="form-tooltip-icon"
                  text={formInputConfig.cloudServiceProvider.tooltip}
                />
              </p>
              <Select
                options={formInputConfig.cloudServiceProvider.options}
                onChange={handleCloudServiceProviderChange}
                className={classNames({ "is-invalid": (cloudServiceProviderError) })}
              />
              <div className="text-danger pt-2"><small>{cloudServiceProviderError}</small></div>
            </CCol>
          </CRow>

          <CRow className='py-2 justify-content-center'>
            <CCol lg={6} md={8} sm={12}>
              <p className="field-label font-weight-bold">
                {formInputConfig.environmentTypes.label}
                {" "}
                <Tooltip
                  className="form-tooltip-icon"
                  text={formInputConfig.environmentTypes.tooltip}
                />
              </p>
              <Select
                isMulti
                options={formInputConfig.environmentTypes.options}
                onChange={handleEnvironmentTypesChange}
                className={classNames({ "is-invalid": (environmentTypesError) })}
              />
              <div className="text-danger pt-2"><small>{environmentTypesError}</small></div>
            </CCol>
          </CRow>

          <CRow className='py-2 justify-content-center'>
            <CCol lg={6} md={8} sm={12}>
              <CFormGroup>
                <CLabel className="field-label font-weight-bold">
                  {formInputConfig.buildHelp.label}
                  {" "}
                  <Tooltip
                    className="form-tooltip-icon"
                    text={formInputConfig.buildHelp.tooltip}
                  />
                </CLabel>
                <div>
                  {formInputConfig.buildHelp.options.map(option => (
                    <CFormGroup variant="custom-radio" inline key={option.key} className="custom-radio">
                      <CInputRadio
                        custom
                        id={option.value}
                        name={formInputConfig.buildHelp.name}
                        value={option.value}
                        onChange={handleBuildHelpChange}
                        className={classNames({ "is-invalid": (buildHelpError) })}
                      />
                      <CLabel variant="custom-checkbox" htmlFor={option.value}>{option.label}</CLabel>
                    </CFormGroup>
                  ))}
                </div>
                <div className="text-danger pt-2"><small>{buildHelpError}</small></div>
              </CFormGroup>
            </CCol>
          </CRow>

        </CContainer>

        <CRow className="justify-content-center m-4">
          <CCol md={{ span: 6, offset: 2 }} className="mr-n5">
            <FormActions onSubmit={handleFormSubmit} onCancel={handleFormCancel} />
          </CCol>
        </CRow>
      </LoadingSpinnerOverlay>
    </CContainer>
  )
}