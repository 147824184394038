import {
  CInputCheckbox,
  CModal,
  CModalFooter,
  CModalHeader,
  CModalTitle
} from "@coreui/react"
import Close from "assets/icons/modalClose.svg"
import { FormActions } from "core/modules/Service/components/ServiceRequest"
import PropTypes from "prop-types"
import React, { useRef, useState, useEffect } from "react"
import styled from "styled-components"
import { useHistory } from "react-router-dom";

const TermsAndConditionsBodyText = styled.div`
  hyphens: auto;
  text-align: justify;
  line-height: 1.5;
`

const TermsAndConditionsBody = styled.div`
  height: 300px;
  overflow-y: auto;
  ${TermsAndConditionsBodyText}:not(:last-child) {
    margin-bottom: 0.5rem !important;
  }
`

export default function CMSTermsAndConditions({
  show,
  setShow,
  setSelect,
  resetStates
}) {
  const history = useHistory()
  const scrollRef = useRef(null)
  const [hasRead, setHasRead] = useState(false)
  const [hasCheckedTermsAndConditions, setHasCheckedTermsAndConditions] = useState(false)
  useEffect(() => {
      window.scrollTo(0, 0)
  }, [])

  const handleScroll = (e) => {
    const bottom =
      Math.abs(
        e.target.scrollHeight - (e.target.scrollTop + e.target.clientHeight)
      ) <= 1
    if (bottom) {
      setHasRead(true)
    }
  }

  const handleClose = () => {
    resetStates(false);
    setHasRead(false)
    setHasCheckedTermsAndConditions(false)
    setShow(false)
    history.push("/landing")
  }

  const handleAccept = () => {
    setSelect(true)
    setShow(false)
    resetStates(true);
  }

  const handleButtonDisable = () => {
    if (!hasRead) {
      return true
    }

    if (!hasCheckedTermsAndConditions) {
      return true
    }

    return false
  }

  const getTermsAndConditionsHeader = () => {
    return (
      <CModalHeader className="bg-body-color">
        <CModalTitle>
          Terms and Conditions - Cloud Hosting Request
        </CModalTitle>
        <img
          src={Close}
          alt="close-icon"
          className="cursor-pointer close-icon"
          onClick={handleClose}
        />
      </CModalHeader>
    )
  }

  const getTermsAndConditionsBody = () => {
    return (
      <TermsAndConditionsBody
        ref={scrollRef}
        onScroll={handleScroll}
        className="py-2 px-5"
      >
        <TermsAndConditionsBodyText>
          <strong>1. Approval:</strong> 
          <ol>
            <li type="a">
              Acceptance of these terms and conditions by a partner responsible for the use of the requested cloud hosted account(s) (the "<strong>Account</strong>" or "<strong>Accounts</strong>") and related charges shall be required prior to provisioning. 
            </li>
          </ol>
        </TermsAndConditionsBodyText>

        <TermsAndConditionsBodyText>
          <strong>2. Types of Account:</strong>  
          <ol>
            <li type="a">
              You may request an Account or Accounts offered by the third party:
               <ul>
                <li type="i">Amazon Web Services (AWS)</li>
                <li type="i">Microsoft</li>
                <li type="i">Google</li>
               </ul>
               referred to individually as (the "<strong>Cloud Service Provider</strong>" or "<strong>CSP</strong>") and collectively as (the "Cloud Service Providers" or "CSPs"). 
            </li>
            <li type="a">
                You may request an Account or Accounts for the following purposes:
                <ul>
                    <li type="i">Sandbox Account (the "Sandbox Account"); </li>
                    <li type="i">Non-Production Account (the "Non-Production Account"); </li>
                    <li type="i">Production Account (the "Production Account"). </li>
                </ul>
                referred to individually as (the "<strong>Cloud Service Provider</strong>" or "<strong>CSP</strong>") and collectively as (the "Cloud Service Providers" or "CSPs"). 
            </li>
            <li type="a">An Account type cannot be changed following provisioning, a new Account of the required type shall be required. </li>
            <li type="a">A Sandbox Account shall be a temporary Account that is automatically terminated 90 days following provision, the contents of the Sandbox Account shall be irretrievably deleted upon termination. </li>
          </ol>
        </TermsAndConditionsBodyText>

        <TermsAndConditionsBodyText>
          <strong>3. Microsoft Independence:</strong>  
          <ol>
            <li type="a">
              Prior to requesting an Account offered by the CSP Microsoft, you will confirm with your Audit Independence Specialist or Team that such use of Microsoft services is permitted by the relevant independence policy. 
            </li>
          </ol>
        </TermsAndConditionsBodyText>

        <TermsAndConditionsBodyText>
          <strong>4. Deloitte Security and Compliance:</strong> 
          <ol>
            <li type="a">With effect from the provisioning date, infrastructure you provision into the Account <u>cannot</u> be externally accessed. You will complete the firm's Information Security Intake process and gain written approval to connect the Production Account to an external network including the Internet. You agree that all costs relating to this process including a penetration test (where required) and any remedial work shall be your responsibility. </li>
            <li type="a">Services accessible by the Client shall be hosted within a Production Account and subject to the firm's Security Intake process, Client access to a Non-Production Account or Sandbox Account is expressly forbidden.</li>
            <li type="a">You will use the Account(s) in accordance with the Deloitte Global Technology Operating Model ("<strong>GTOM</strong>") and it is your responsibility to engage with the appropriate GTOM process. </li>
            <li type="a">You will implement and maintain the services hosted within the Account(s) in accordance with the firm policy SS.07 Secure System Development Lifecycle. </li>
            <li type="a">Any requirement for hosting Deloitte or Client data within the Production Account requires written approval from Deloitte Business Security prior to any data being imported into the Production Account. Approval must be shared with Cloud Managed Services. Deloitte or Client data cannot under any circumstances be stored within a Sandbox Account or Non-Production Account. </li>
            <li type="a">You will ensure any requirements in respect of data residency; processing and the relevant data protection legislation are satisfied in full. </li>
            <li type="a">To maintain alignment with GTOM and SS.07 Secure System Development Lifecycle standards, you will ensure that CMS is informed of all service or technical changes made to the hosting environment.</li>
          </ol>
          </TermsAndConditionsBodyText>

          <TermsAndConditionsBodyText>
          <strong>5. Access Management:</strong> 
          <ul>
            <li type="a">Cloud Managed Services will manage access to the Accounts. Access to Accounts shall be limited to Deloitte employees and Associates. In the event of Account access being required for a third party, you will obtain the necessary written approval from Deloitte Business Security in advance of requesting such access. </li>
            <li type="a">User access shall be provisioned in accordance with the relevant Deloitte access management policies. </li>
          </ul>
        </TermsAndConditionsBodyText>

        <TermsAndConditionsBodyText>
          <strong>6. Acceptable Usage:</strong> 
          <ul>
            <li type="a">You will use the Account(s) in accordance with the relevant CSP usage agreement as updated from time to time. </li>
            <li type="a">Prior to using Generative AI services, you will obtain written approval from the relevant QRM Digital Advisor. </li>
            <li type="a">All Generative AI concepts shall be proven within a Sandbox Account, shall solely use tools approved for this purpose by the firm’s Office of Gen AI and comply with the relevant Generative AI Risk Guardrails as updated from time to time. For the purposes of clarity, Generative AI services must not be deployed within any Account prior to written approval from the relevant QRM Digital Advisor. </li>
            <li type="a">Deployment of Generative AI services within a Non-Production Account or Production Account shall be subject to a successful proof of concept within a Sandbox Account. </li>
          </ul>
        </TermsAndConditionsBodyText>

        <TermsAndConditionsBodyText>
          <strong>7. Account Provisioning:</strong> 
          <ul>
            <li type="a">Cloud Managed Services will provision the Account(s) in accordance with the agreed specification (the "Account Provisioning"), you agree to satisfy the dependencies set out within this specification. </li>
          </ul>
        </TermsAndConditionsBodyText>

        <TermsAndConditionsBodyText>
          <strong>8. Managed Service:</strong> 
          <ul>
            <li type="a">Cloud Managed Services will manage the Account(s) in accordance with the service specification for the relevant support tier (the "<strong>Managed Service</strong>"); </li>
            <li type="a">Support tiers vary by Account type and use-case, and include: </li>
              <ul>
                <li type="i">Self-managed; </li>
                <li type="i">Co-managed; and </li>
                <li type="i">Fully-managed. </li>
              </ul>
            <li type="a">Cloud Managed Services reserves the right to refuse the provision of a support tier that in its judgement is inappropriate for the Account type or use-case. </li>
            <li type="a">You agree to satisfy the dependencies set out within the service specification for the relevant support tier. </li>
          </ul>
        </TermsAndConditionsBodyText>

        <TermsAndConditionsBodyText>
          <strong>9. Charges:</strong> 
          <ul>
            <li type="a">You will pay charges related to:</li>
            <ul>
              <li type="i">The Account Provisioning; </li>
              <li type="i">The Managed Service; </li>
              <li type="i">The CSP consumption and related tooling fees in respect of the Accounts; and </li>
              <li type="i">Where applicable, the CSP support fee in respect of the Accounts. </li>
            </ul>
          </ul>
        </TermsAndConditionsBodyText>
      </TermsAndConditionsBody>
    )
  }

  const getTermsAndConditionsFooter = () => {
    return (
      <CModalFooter className="bg-body-color flex-column">
        <div className="py-2 px-5 align-self-start">
          <div>
            <CInputCheckbox
              style={{ height: "15px", width: "15px" }}
              checked={hasCheckedTermsAndConditions}
              disabled={!hasRead}
              onChange={() => {
                setHasCheckedTermsAndConditions(!hasCheckedTermsAndConditions)
              }}
            />
            <p style={{ color: "#111" }}>
              I confirm that I have read and will abide by the Terms &
              Conditions set by Cloud Managed Services.
            </p>
          </div>
        </div>
        <div className="align-self-end">
          <FormActions
            onSubmit={handleAccept}
            onCancel={() => {
              handleClose()
            }}
            isSubmitDisabled={handleButtonDisable()}
            cancelBtnStyling="mr-4"
            submitText="Accept"
            cancelText="Decline"
          />
        </div>
      </CModalFooter>
    )
  }


  return (
    show && (
      <CModal
        show={show}
        onClose={handleClose}
        className="pt-3"
        size="lg"
        fade={false}
      >
        {getTermsAndConditionsHeader()}
        {getTermsAndConditionsBody()}
        {getTermsAndConditionsFooter()}
      </CModal>
    )
  )
}

CMSTermsAndConditions.propTypes = {
  show: PropTypes.bool,
  setShow: PropTypes.func,
  setSelect: PropTypes.func,
  resetStates: PropTypes.func
}
